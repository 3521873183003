* {
    box-sizing: border-box;
}
body {
    font-family: 'Rubik', 'Arial', sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #5e5e5e;
    background-color: #191a1d;
    width: 80%;
    margin: auto;
    padding: 60px;
}
@media (max-width: 1024px) {
    body {
        color: #5e5e5e;
        padding: 60px 30px 0px 30px;
        width: 100%;
        margin: 0;
    }
}
